import type { ColumnDef } from "@tanstack/react-table";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";

import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

type ReactTableProps<D extends unknown> = {
  data: D[];
  columns: ColumnDef<D, string>[];
  headerProps?: {};
  headerCellProps?: {};
  bodyProps?: {};
  rowDataProps?: {};
  cellProps?: {};
  tableProps?: {};
  variant?: string;
  size?: string;
  [x: string]: any;
};

const ReactTable = <D extends unknown>({
  columns,
  data = [],
  headerProps = {},
  headerCellProps = {},
  bodyProps = {},
  rowDataProps = {},
  cellProps = {},
  tableProps = {},
  variant = "",
  size = "",
  ...restConfig
}: ReactTableProps<D>) => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  });

  const tableConfig = {
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
    ...restConfig,
  };

  const table = useReactTable(tableConfig);

  // Render the UI for your table
  return (
    <TableContainer overflow={"scroll"} width={"100%"} fontSize={10}>
      <Table
        className={restConfig["className"] || ""}
        variant={variant}
        size={size}
        {...tableProps}
      >
        <Thead {...headerProps} fontSize={10}>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id} fontSize={10}>
              {headerGroup.headers.map((header: any) => (
                <Th
                  {...header.column.columnDef?.meta}
                  key={header.id}
                  {...headerCellProps}
                  fontSize={10}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...bodyProps}>
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id} {...rowDataProps}>
              {row.getVisibleCells().map((cell) => (
                <Td key={cell.id} {...cellProps} overflow="hidden" textOverflow="ellipses" whiteSpace="nowrap">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export { ReactTable };
