import { ChevronDownIcon, CloseIcon, EditIcon, SearchIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  DrawerBody,
  DrawerHeader,
  Flex,
  HStack,
  Heading,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import AppViewDrawer from "components/AppViewDrawer";
import AppViewListItem from "components/AppViewListItem";
import { Pagination } from "components/Pagination";
import { useApi } from "hooks/useApi";
import { useAuthentication } from "hooks/useAuthentication";
import { useCache } from "hooks/useCache";
import { useCallback, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Store } from "store";
import { showToast, toastTypes } from "utils";
import { ReactTable } from "../../components/ReactTable";

type Table3RowType = {
  id: number;
  name: string;
  email: string;
  phone_number: string;
  actionHeader: undefined;
  profile_picture: string;
  country_dial_code: string;
  is_active:boolean;
};

export default function DealerManagementSection() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeItem, setActiveItem] = useState(null);
  const toast = useToast();
  const navigate = useNavigate();
  const { dispatch } = useContext(Store);
  const {hasPermission} = useAuthentication()
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [searchBarValue34, setSearchBarValue34] = useState("");
  const { getDealers, updateDealer } = useApi();
  const { data: dealers, mutate } = useCache("dealers", () => getDealers(page,searchBarValue34));
  const handleSearch = (event: any) => {
    const value = event.target.value
    mutate(getDealers(page,value))
  };
  const handleClearSearch = (event: any) => {
    setSearchBarValue34("");
    mutate(getDealers(page,""))
  }

  const handleJumpToPage = (pg: number) => {
    setPage(pg);
    mutate(getDealers(pg));
  };
  const handleSetNextPage = () => {
    if (dealers.length > 0 && dealers[0].num_of_pages >= page + 1) {
      setPage((prev: any) => prev + 1);
      mutate(getDealers(page + 1));
    }
  };
  const handleSetPrevPage = () => {
    if (dealers.length > 0 && page > 0) {
      setPage((prev: any) => prev - 1);
      mutate(getDealers(page + 1));
    }
  };

  const handleViewItem = useCallback(
    (item: any) => {
      setActiveItem(item);
      onOpen();
    },
    [onOpen, setActiveItem]
  );

  const handleUpdateActive = useCallback(async (dealer:any) => {
    // const postData = {...client, address:{...client?.address}, is_active: client?.is_active ? false : true}
    let formData: any = new FormData();
    formData.append("first_name", dealer.first_name);
    formData.append("last_name", dealer.last_name);
    formData.append("phone_number", dealer.phone_number);
    formData.append("country_id", dealer.country_id);
    formData.append("email", dealer.email);
    formData.append("id", dealer.id);
    formData.append("is_active", dealer?.is_active ? false : true);
    const response = await updateDealer(formData)
    if(response.id){
      dispatch(
        showToast({
          toast: toast,
          message: "Client mis à jour avec succès",
          status: toastTypes.success,
        })
      );
      mutate(getDealers(page, searchBarValue34))
    }
  },[dispatch, getDealers, mutate, page, searchBarValue34, toast, updateDealer])

  const table3Columns = useMemo(() => {
    const table3ColumnHelper = createColumnHelper<Table3RowType>();
    return [
      table3ColumnHelper.accessor("name", {
        cell: (info) => (
          <Box flex={1}>
            <Flex gap="8px" alignItems="center" px="12px" py="0px">
              <Image
                src={info.row.original.profile_picture as unknown as string}
                alt="Dealer Profile Image"
                h="32px"
                w="32px"
                fit="contain"
                borderRadius="10px"
              />
              <Text>{info.getValue<string>()}</Text>
            </Flex>
          </Box>
        ),
        header: (info) => (
          <Flex flex={1} px={"25px"} py="14px">
            <Heading as="h6">Dealer</Heading>
          </Flex>
        ),
        meta: { width: "190px" },
      }),
      table3ColumnHelper.accessor("email", {
        cell: (info) => (
          <Flex px={{ base: "20px", sm: "24px" }}>
            <Text>{info.getValue<string>()}</Text>
          </Flex>
        ),
        header: (info) => (
          <Flex p="14px" px={"40px"}>
            <Heading as="h6">Email</Heading>
          </Flex>
        ),
        meta: { width: "132px" },
      }),
      table3ColumnHelper.accessor("phone_number", {
        cell: (info) => (
          <Flex px={{ base: "20px", sm: "24px" }}>
            <Text>
              ({info.row.original.country_dial_code as unknown as string}){" "}
              {info.getValue<string>()}
            </Text>
          </Flex>
        ),
        header: (info) => (
          <Flex p="14px" px={"40px"}>
            <Heading as="h6">Numéro de téléphone</Heading>
          </Flex>
        ),
        meta: { width: "132px" },
      }),
      table3ColumnHelper.accessor("is_active", {
        cell: (info) => (
          <Flex justifyContent="flex-start" width={"100px"}>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                size={"sm"}
                colorScheme={info.getValue<boolean>() ? "green_360" : "red_origin"}
                borderRadius={"4px"}
                px={"5px"}
                fontSize={"10px"}
              >
                {info.getValue<boolean>() ? "Actif" : "Inactif"}
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => handleUpdateActive(info.row.original)}>
                  <span>
                    {info.getValue<boolean>() ? "Désactiver" : "Activer"}
                  </span>
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        ),
        header: (info) => (
          <Flex px="15px" py="14px" justifyContent={"flex-start"}>
            <Heading as="h6">État</Heading>
          </Flex>
        ),
        meta: { width: "100px" },
      }),
      table3ColumnHelper.accessor("id", {
        cell: (info) => (
          <HStack gap={"2px"} width={"100%"} justifyContent={"flex-end"}>
            {hasPermission("dealer", "update") &&<IconButton
              variant="outline"
              colorScheme="gray_stroke_0"
              aria-label="Edit"
              size={"sm"}
              icon={<EditIcon />}
              borderRadius={"4px"}
              onClick={() => navigate(`edit/${info.getValue()}`)}
            />}
            {hasPermission("dealer", "get") &&<IconButton
              variant="outline"
              colorScheme="gray_stroke_0"
              aria-label="view"
              size={"sm"}
              icon={<ViewIcon />}
              borderRadius={"4px"}
              onClick={() => handleViewItem(info.row.original)}
            />}
          </HStack>
        ),
        header: (info) => (
          <Flex width={"100%"} justifyContent={"flex-end"} px="15px" py="14px">
            <Heading as="h6">Action</Heading>
          </Flex>
        ),
        meta: { width: "100px" },
      }),
    ];
  }, [navigate, handleViewItem, hasPermission, handleUpdateActive]);

  return (
    <>
      {/* dealer management section */}
      <Flex
        flexDirection="column"
        alignItems="start"
        px={{ base: "20px", sm: "24px" }}
        minHeight={"100vh"}
      >
        <Flex gap="4px" alignSelf="stretch" flexDirection="column">
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection={{ base: "column", sm: "row" }}
          >
            <Heading size="text2xl" as="h5" fontFamily="Arimo">
              Dealers
            </Heading>
            <Flex
              pl="56px"
              pr="130px"
              gap="13px"
              flex={1}
              justifyContent="flex-end"
              alignItems="center"
              alignSelf={{ base: "stretch", sm: "auto" }}
              flexDirection={{ base: "column", sm: "row" }}
              px={{ md: 0, base: "20px" }}
            >
              
              {hasPermission("dealer", "create") &&<Button
                size="xl"
                variant="outline"
                colorScheme="red_origin"
                leftIcon={
                  <Image
                    src="images/img_handshake_deal_svgrepo_com_red_origin.svg"
                    alt="Handshake-deal Svgrepo.com"
                  />
                }
                fontWeight={700}
                gap="8px"
                minW="128px"
                borderRadius="8px"
                as={Link}
                href="/dealers/add"
              >
                Créer un dealer
              </Button>}
            </Flex>
          </Flex>
        </Flex>
        <Box
          mt="16px"
          bg="white"
          w={{ md: "100%", base: "100%" }}
          borderRadius="10px"
        >
          <Flex
            bg="white"
            justifyContent="center"
            alignItems="center"
            px="12px"
            py="16px"
            flexDirection={{ md: "row", base: "column" }}
          >
            <Flex
              flex={1}
              alignItems="center"
              alignSelf={{ md: "auto", base: "stretch" }}
              flexDirection={{ base: "column", sm: "row" }}
            >
              <InputGroup w={{ base: "100%", sm: "30%" }}>
                <InputLeftElement>
                  <Image
                    src="images/img_search_black.svg"
                    alt="Search"
                    w="16px"
                    h="14px"
                  />
                </InputLeftElement>
                <Input
                  placeholder={`Rechercher un dealer`}
                  value={searchBarValue34}
                  onChange={(event:any) => setSearchBarValue34(event.currentTarget.value)}
                  gap="8px"
                  borderWidth="1px"
                  borderRadius="8px"
                />
                <InputRightElement>
                  {searchBarValue34?.length > 0 ? (
                    <CloseIcon onClick={handleClearSearch} />
                  ) : null}
                </InputRightElement>
              </InputGroup>
                <IconButton onClick={handleSearch} ml={2} borderRadius={"10px"} py={"19px"} border={"1px solid #e2e4e6"} aria-label="search" icon={<SearchIcon/>} justifyContent={"center"} alignItems={"center"} />
            </Flex>
            <Image
              src="images/img_dot_menu.svg"
              alt="Menu Image"
              h="24px"
              w={{ md: "24px", base: "100%" }}
            />
          </Flex>
          <ReactTable
            size="sm"
            headerCellProps={{ bg: "white" }}
            tableProps={{
              borderColor: "neutral.600",
              borderTopWidth: "1px",
              borderStyle: "solid",
            }}
            columns={table3Columns as any}
            data={dealers}
          />
          <Pagination
            pageIndex={page}
            pageSize={pageSize}
            setNextPageIndex={handleSetNextPage}
            setPrevPageIndex={handleSetPrevPage}
            setPageSize={setPageSize}
            jumpToPage={handleJumpToPage}
            numberOfPages={dealers && dealers.length > 0 && dealers[0].num_of_pages}
          />
        </Box>
      </Flex>
      <AppViewDrawer isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <DrawerHeader>Fiche Dealer</DrawerHeader>

        <DrawerBody>
          <VStack alignItems={"flex-start"} gap={"15px"}>
            <HStack>
              <Button
                size="xl"
                variant="outline"
                colorScheme="green_360"
                leftIcon={<EditIcon />}
                fontWeight={700}
                gap="2px"
                borderRadius="8px"
                as={Link}
                href={`/dealers/edit/${activeItem?.id}`}
              >
                Modifier
              </Button>
            </HStack>
            <VStack alignItems={"center"} gap={"5px"} width={"100%"}>
              <Flex gap="8px" padding={"1px"}>
                <Image
                  src={activeItem?.profile_picture}
                  fallbackSrc="/images/dummy.png"
                  alt="logo Image"
                  h="100px"
                  w="100px"
                  fit="contain"
                  borderRadius="16px"
                />
              </Flex>
            </VStack>
            <AppViewListItem label="Nom du client" value={activeItem?.name} />
            <AppViewListItem label="Email" value={activeItem?.email} />
            <AppViewListItem
              label="Phone Number"
              value={`(${activeItem?.country_dial_code}) ${activeItem?.phone_number}`}
            />
          </VStack>
        </DrawerBody>
      </AppViewDrawer>
    </>
  );
}
