import { CloseIcon, SearchIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Checkbox,
  DrawerBody,
  DrawerHeader,
  Flex,
  HStack,
  Heading,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import AppViewDrawer from "components/AppViewDrawer";
import AppViewListItem from "components/AppViewListItem";
import { Pagination } from "components/Pagination";
import { useApi } from "hooks/useApi";
import { useCache } from "hooks/useCache";
import { useCallback, useMemo, useState } from "react";
import { MdMap } from "react-icons/md";
import Zoom from "react-medium-image-zoom";
import { ReactTable } from "../../components/ReactTable";

type Table3RowType = {
  id: number;
  customer_name: string;
  date: string;
  comment: string;
  complaint_images: any;
  actionHeader: undefined;
  customer_image: string;
  longitude: string;
  latitude: string;
  product_name: string;
};

export default function ComplaintManagementSection() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeItem, setActiveItem] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [searchBarValue34, setSearchBarValue34] = useState("");
  const { getComplaints } = useApi();
  const { data: complaints, mutate } = useCache("complaints", () =>
    getComplaints(page, searchBarValue34)
  );
  const handleSearch = (event: any) => {
    const value = event.target.value;
    mutate(getComplaints(page, value));
    // if (event.key === "Enter") {
    //   setSearchBarValue34(value);
    // } else {
    //   setSearchBarValue34(value);
    // }
  };
  const handleViewItem = useCallback(
    (item: any) => {
      setActiveItem(item);
      onOpen();
    },
    [onOpen, setActiveItem]
  );
  const handleClearSearch = (event: any) => {
    setSearchBarValue34("");
    mutate(getComplaints(page, ""));
  };
  const handleJumpToPage = (pg: number) => {
    setPage(pg);
    mutate(getComplaints(pg));
  };
  const handleSetNextPage = () => {
    if (complaints.length > 0 && complaints[0].num_of_pages >= page + 1) {
      setPage((prev: any) => prev + 1);
      mutate(getComplaints(page + 1));
    }
  };
  const handleSetPrevPage = () => {
    if (complaints.length > 0 && page > 0) {
      setPage((prev: any) => prev - 1);
      mutate(getComplaints(page + 1));
    }
  };
  const table3Columns = useMemo(() => {
    const table3ColumnHelper = createColumnHelper<Table3RowType>();
    return [
      table3ColumnHelper.accessor("product_name", {
        cell: (info) => (
          <Flex justifyContent="center">
            <Flex gap="8px" w="100%" alignItems="center" px="12px" py="0px">
              <Checkbox value="undefined" />
              <Text>{info.getValue<string>()}</Text>
            </Flex>
          </Flex>
        ),
        header: (info) => (
          <Flex p="25px">
            <Flex gap="8px" w={{ md: "42%", base: "100%" }} alignItems="center">
              <Checkbox value="undefined" />
              <Heading as="h6">Produit</Heading>
            </Flex>
          </Flex>
        ),
        meta: { width: "170px" },
      }),
      table3ColumnHelper.accessor("customer_name", {
        cell: (info) => (
          <Flex justifyContent="center">
            <Flex gap="8px" w="100%" alignItems="center" px="12px" py="0px">
              <Checkbox value="undefined" />
              <Image
                src={
                  (info.row?.original?.customer_image as unknown as string) ||
                  "/images/dummy.png"
                }
                alt="customer profile picture"
                h="32px"
                w="32px"
                fit="contain"
                borderRadius="16px"
              />
              <Text>{info.getValue<string>()}</Text>
            </Flex>
          </Flex>
        ),
        header: (info) => (
          <Flex p="25px">
            <Flex gap="8px" w={{ md: "42%", base: "100%" }} alignItems="center">
              <Checkbox value="undefined" />
              <Heading as="h6">Utilisateur</Heading>
            </Flex>
          </Flex>
        ),
        meta: { width: "170px" },
      }),
      table3ColumnHelper.accessor("date", {
        cell: (info) => (
          <Box flex={1}>
            <Flex gap="8px" alignItems="center" px="12px" py="0px">
              <Text>{info.getValue<string>()}</Text>
            </Flex>
          </Box>
        ),
        header: (info) => (
          <Flex flex={1} px="25px" py="14px">
            <Heading as="h6">Date de création</Heading>
          </Flex>
        ),
        meta: { width: "212px" },
      }),
      table3ColumnHelper.accessor("complaint_images", {
        cell: (info) => (
          <Flex px={{ base: "20px", sm: "24px" }}>
            <Flex gap="8px" w="100%" alignItems="center" px="12px" py="10px">
              <Checkbox value="undefined" />
              <Image
                src={info.row.original?.complaint_images[0] as unknown as any}
                alt="complaints images"
                h="32px"
                w="32px"
                fit="contain"
                borderRadius="8px"
              />
            </Flex>
          </Flex>
        ),
        header: (info) => (
          <Flex p="14px" px="40px">
            <Heading as="h6">Images</Heading>
          </Flex>
        ),
        meta: { width: "132px" },
      }),
      table3ColumnHelper.accessor("id", {
        cell: (info) => (
          <HStack gap={"2px"}>
            <IconButton
              variant="outline"
              colorScheme="gray_stroke_0"
              aria-label="view"
              size={"sm"}
              icon={<ViewIcon />}
              borderRadius={"4px"}
              onClick={() => handleViewItem(info.row.original)}
            />
            <Link
              href={`https://www.google.com/maps/search/?api=1&query=${info.row.original.latitude},${info.row.original.longitude}`}
              target="_blank"
            >
              <IconButton
                variant="outline"
                colorScheme="gray_stroke_0"
                aria-label="view"
                size={"sm"}
                icon={<MdMap />}
                borderRadius={"4px"}
                onClick={() => handleViewItem(info.row.original)}
              />
            </Link>
          </HStack>
        ),
        header: (info) => (
          <Flex px="15px" py="14px">
            <Heading as="h6">Action</Heading>
          </Flex>
        ),
        meta: { width: "100px" },
      }),
    ];
  }, [handleViewItem]);

  return (
    <>
      {/* complaint management section */}
      <Flex
        flexDirection="column"
        alignItems="start"
        px={{ base: "20px", sm: "24px" }}
        minHeight={"100vh"}
      >
        <Flex gap="4px" alignSelf="stretch" flexDirection="column">
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection={{ base: "column", sm: "row" }}
          >
            <Heading size="text2xl" as="h5" fontFamily="Arimo">
              Signalements
            </Heading>
            <Flex
              pl="56px"
              pr="130px"
              gap="13px"
              flex={1}
              justifyContent="flex-end"
              alignItems="center"
              alignSelf={{ base: "stretch", sm: "auto" }}
              flexDirection={{ base: "column", sm: "row" }}
              px={{ md: 0, base: "20px" }}
            ></Flex>
          </Flex>
        </Flex>
        <Box
          mt="16px"
          bg="white"
          w={{ md: "100%", base: "100%" }}
          borderRadius="10px"
        >
          <Flex
            bg="white"
            justifyContent="center"
            alignItems="center"
            px="12px"
            py="16px"
            flexDirection={{ md: "row", base: "column" }}
          >
            <Flex
              flex={1}
              alignItems="center"
              alignSelf={{ md: "auto", base: "stretch" }}
              flexDirection={{ base: "column", sm: "row" }}
            >
              <InputGroup w={{ base: "100%", sm: "30%" }}>
                <InputLeftElement>
                  <Image
                    src="images/img_search_black.svg"
                    alt="Search"
                    w="16px"
                    h="14px"
                  />
                </InputLeftElement>
                <Input
                  placeholder={`Rechercher un produit`}
                  value={searchBarValue34}
                  onChange={(event: any) =>
                    setSearchBarValue34(event.currentTarget.value)
                  }
                  gap="8px"
                  borderWidth="1px"
                  borderRadius="8px"
                />
                <InputRightElement>
                  {searchBarValue34?.length > 0 ? (
                    <CloseIcon onClick={handleClearSearch} />
                  ) : null}
                </InputRightElement>
              </InputGroup>
              <IconButton
                onClick={handleSearch}
                ml={2}
                borderRadius={"10px"}
                py={"19px"}
                border={"1px solid #e2e4e6"}
                aria-label="search"
                icon={<SearchIcon />}
                justifyContent={"center"}
                alignItems={"center"}
              />
            </Flex>
            <Image
              src="images/img_dot_menu.svg"
              alt="Menu Image"
              h="24px"
              w={{ md: "24px", base: "100%" }}
            />
          </Flex>
          <ReactTable
            size="sm"
            headerCellProps={{ bg: "white" }}
            tableProps={{
              borderColor: "neutral.600",
              borderTopWidth: "1px",
              borderStyle: "solid",
            }}
            columns={table3Columns as any}
            data={complaints}
          />
          <Pagination
            pageIndex={page}
            pageSize={pageSize}
            setNextPageIndex={handleSetNextPage}
            setPrevPageIndex={handleSetPrevPage}
            setPageSize={setPageSize}
            jumpToPage={handleJumpToPage}
            numberOfPages={
              complaints && complaints.length > 0 && complaints[0]?.num_of_pages
            }
          />
        </Box>
      </Flex>
      <AppViewDrawer isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <DrawerHeader>Fiche Signalement</DrawerHeader>

        <DrawerBody>
          <VStack alignItems={"flex-start"} gap={"15px"}>
            <VStack alignItems={"center"} gap={"5px"} width={"100%"}>
              <Flex gap="8px" padding={"1px"}>
                <Zoom>
                  <Image
                    src={activeItem?.complaint_images[0]}
                    fallbackSrc="/images/dummy.png"
                    alt="logo Image"
                    h="100px"
                    w="100px"
                    fit="contain"
                    borderRadius="16px"
                  />
                </Zoom>
              </Flex>
            </VStack>
            <AppViewListItem
              label="Produit ID"
              value={activeItem?.product_token}
            />
            <AppViewListItem
              label="Utilisateur"
              value={activeItem?.customer_name}
            />
            <AppViewListItem label="Date et heure" value={activeItem?.date} />
            <AppViewListItem label="Commentaire" value={activeItem?.comment} />
            <AppViewListItem
              label="Images"
              value={
                <Flex
                  gap="8px"
                  w="100%"
                  alignItems="center"
                  px="12px"
                  py="16px"
                >
                  {activeItem?.complaint_images?.map((image, index) => (
                    <Zoom>
                      <Image
                        key={index}
                        src={image as unknown as string}
                        alt="complaints images"
                        h="50px"
                        w="50px"
                        fit="contain"
                        borderRadius="10px"
                      />
                    </Zoom>
                  ))}
                </Flex>
              }
            />
          </VStack>
        </DrawerBody>
      </AppViewDrawer>
    </>
  );
}
