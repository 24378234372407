import { ChevronDownIcon, CloseIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToast,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { Pagination } from "components/Pagination";
import { useApi } from "hooks/useApi";
import { useAuthentication } from "hooks/useAuthentication";
import { useCache } from "hooks/useCache";
import { useCallback, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Store } from "store";
import { showToast, toastTypes } from "utils";
import { ReactTable } from "../../components/ReactTable";

type Table3RowType = {
  id: number;
  name: string;
  email: string;
  phone_number: string;
  roles: string;
  alias: string;
  profile_picture?: string;
  company_name?: string;
  is_active?: boolean;
};

export default function UserManagementSection() {
  const toast = useToast();
  const navigate = useNavigate();
  const { dispatch } = useContext(Store);
  const {hasPermission} = useAuthentication()
  const [searchBarValue34, setSearchBarValue34] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const { getUsers, toggleUserActive } = useApi();
  const { data: users, mutate } = useCache("users", () => getUsers(page, searchBarValue34));
  const handleSearch = () => {
    mutate(getUsers(page, searchBarValue34))
  };

  const handleJumpToPage = (pg: number) => {
    setPage(pg);
    mutate(getUsers(pg));
  };
  const handleSetNextPage = () => {
    if (users.length > 0 && users[0].num_of_pages >= page + 1) {
      setPage((prev: any) => prev + 1);
      mutate(getUsers(page + 1));
    }
  };
  const handleSetPrevPage = () => {
    if (users.length > 0 && page > 0) {
      setPage((prev: any) => prev - 1);
      mutate(getUsers(page + 1));
    }
  };

  const handleUpdateActive = useCallback(async (sysUser:any) => {
    const postData = {...sysUser, group_name:sysUser.alias, alias:sysUser.alias, is_active: sysUser?.is_active ? false : true}
    const response = await toggleUserActive(postData)
    if(response.id){
      dispatch(
        showToast({
          toast: toast,
          message: "Utilisateur mis à jour avec succès",
          status: toastTypes.success,
        })
      );
      mutate(getUsers(page, searchBarValue34))
    }
  },[dispatch, getUsers, mutate, page, searchBarValue34, toast, toggleUserActive])
  const table3Columns = useMemo(() => {
    const table3ColumnHelper = createColumnHelper<Table3RowType>();
    return [
      table3ColumnHelper.accessor("company_name", {
        cell: (info) => (
          <Box flex={1}>
            <Flex gap="8px" alignItems="center" px="12px" py="0px">
              <Text>{info.getValue<string>() || ""}</Text>
            </Flex>
          </Box>
        ),
        header: (info) => (
          <Flex flex={1} px={"25px"} py="14px">
            <Heading as="h6">Client</Heading>
          </Flex>
        ),
        meta: { width: "190px" },
      }),
      table3ColumnHelper.accessor("name", {
        cell: (info) => (
          <Box flex={1}>
            <Flex gap="8px" alignItems="center" px="12px" py="0px">
              <Text>{info.getValue<string>()}</Text>
            </Flex>
          </Box>
        ),
        header: (info) => (
          <Flex flex={1} px={"25px"} py="14px">
            <Heading as="h6">Utilisateur</Heading>
          </Flex>
        ),
        meta: { width: "190px" },
      }),
      table3ColumnHelper.accessor("roles", {
        cell: (info) => (
          <Box flex={1}>
            <Flex gap="8px" alignItems="center" px="12px" py="0px">
              <Text textTransform={"capitalize"}>{info.row.original.alias || info.getValue<string>()}</Text>
            </Flex>
          </Box>
        ),
        header: (info) => (
          <Flex flex={1} px={"25px"} py="14px">
            <Heading as="h6">Rôle</Heading>
          </Flex>
        ),
        meta: { width: "190px" },
      }),
      table3ColumnHelper.accessor("email", {
        cell: (info) => (
          <Box flex={1}>
            <Flex gap="8px" alignItems="center" px="12px" py="0px">
              <Text>{info.getValue<string>()}</Text>
            </Flex>
          </Box>
        ),
        header: (info) => (
          <Flex flex={1} px={"25px"} py="14px">
            <Heading as="h6">Email</Heading>
          </Flex>
        ),
        meta: { width: "190px" },
      }),

      table3ColumnHelper.accessor("is_active", {
        cell: (info) => (
          <Flex justifyContent="flex-start" width={"100px"}>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                size={"sm"}
                colorScheme={info.getValue<boolean>() ? "green_360" : "red_origin"}
                borderRadius={"4px"}
                px={"5px"}
                fontSize={"10px"}
              >
                {info.getValue<boolean>() ? "Actif" : "Inactif"}
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => handleUpdateActive(info.row.original)}>
                  <span>
                    {info.getValue<boolean>() ? "Désactiver" : "Activer"}
                  </span>
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        ),
        header: (info) => (
          <Flex px="15px" py="14px" justifyContent={"flex-start"}>
            <Heading as="h6">État</Heading>
          </Flex>
        ),
        meta: { width: "100px" },
      }),
      table3ColumnHelper.accessor("phone_number", {
        cell: (info) => (
          <Box flex={1}>
            <Flex gap="8px" alignItems="center" px="12px" py="0px">
              <Text>{info.getValue<string>()}</Text>
            </Flex>
          </Box>
        ),
        header: (info) => (
          <Flex flex={1} px={"25px"} py="14px">
            <Heading as="h6">Numéro de téléphone</Heading>
          </Flex>
        ),
        meta: { width: "190px" },
      }),
      table3ColumnHelper.accessor("id", {
        cell: (info) => (
          <HStack gap={"2px"} width={"100%"} justifyContent={"flex-end"}>
            {hasPermission("systemuser", "update") && <IconButton
              variant="outline"
              colorScheme="gray_stroke_0"
              aria-label="Edit"
              size={"sm"}
              icon={<EditIcon />}
              borderRadius={"4px"}
              onClick={() => navigate(`edit/${info.getValue()}`)}
            />}
          </HStack>
        ),
        header: (info) => (
          <Flex px="15px" py="14px" justifyContent={"flex-end"}>
            <Heading as="h6">Action</Heading>
          </Flex>
        ),
        meta: { width: "100px" },
      }),
    ];
  }, [navigate, hasPermission, handleUpdateActive]);

  return (
    <>
      {/* dealer management section */}
      <Flex
        flexDirection="column"
        alignItems="start"
        px={{ base: "20px", sm: "24px" }}
      >
        <Flex gap="4px" alignSelf="stretch" flexDirection="column">
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection={{ base: "column", sm: "row" }}
          >
            <Heading size="text2xl" as="h5" fontFamily="Arimo">
              Utilisateurs
            </Heading>
            <Flex
              pl="56px"
              pr="130px"
              gap="13px"
              flex={1}
              justifyContent="flex-end"
              alignItems="center"
              alignSelf={{ base: "stretch", sm: "auto" }}
              flexDirection={{ base: "column", sm: "row" }}
              px={{ md: 0, base: "20px" }}
            >
              
              {hasPermission("systemuser", "create") && <Button
                size="xl"
                variant="outline"
                colorScheme="red_origin"
                leftIcon={
                  <Image
                    src="images/img_handshake_deal_svgrepo_com_red_origin.svg"
                    alt="Handshake-deal Svgrepo.com"
                  />
                }
                fontWeight={700}
                gap="8px"
                minW="128px"
                borderRadius="8px"
                as={Link}
                href="/users/add"
              >
                Créer un Utilisateur
              </Button>}
            </Flex>
          </Flex>
        </Flex>
        <Box
          mt="16px"
          bg="white"
          w={{ md: "100%", base: "100%" }}
          borderRadius="10px"
        >
          <Flex
            bg="white"
            justifyContent="center"
            alignItems="center"
            px="12px"
            py="16px"
            flexDirection={{ md: "row", base: "column" }}
          >
            <Flex
              flex={1}
              alignItems="center"
              alignSelf={{ md: "auto", base: "stretch" }}
              flexDirection={{ base: "column", sm: "row" }}
            >
              <InputGroup w={{ base: "100%", sm: "30%" }}>
                <InputLeftElement>
                  <Image
                    src="images/img_search_black.svg"
                    alt="Search"
                    w="16px"
                    h="14px"
                  />
                </InputLeftElement>
                <Input
                  placeholder={`Rechercher`}
                  value={searchBarValue34}
                  onChange={(event:any) => setSearchBarValue34(event.currentTarget.value)}
                  gap="8px"
                  borderWidth="1px"
                  borderRadius="8px"
                />
                <InputRightElement>
                  {searchBarValue34?.length > 0 ? (
                    <CloseIcon onClick={() => setSearchBarValue34("")} />
                  ) : null}
                </InputRightElement>
              </InputGroup>
                <IconButton onClick={handleSearch} ml={2} borderRadius={"10px"} py={"19px"} border={"1px solid #e2e4e6"} aria-label="search" icon={<SearchIcon/>} justifyContent={"center"} alignItems={"center"} />
            </Flex>
            <Image
              src="images/img_dot_menu.svg"
              alt="Menu Image"
              h="24px"
              w={{ md: "24px", base: "100%" }}
            />
          </Flex>
          <ReactTable
            size="sm"
            headerCellProps={{ bg: "white" }}
            tableProps={{
              borderColor: "neutral.600",
              borderTopWidth: "1px",
              borderStyle: "solid",
            }}
            columns={table3Columns as any}
            data={users}
          />
          <Pagination
            pageIndex={page}
            pageSize={pageSize}
            setNextPageIndex={handleSetNextPage}
            setPrevPageIndex={handleSetPrevPage}
            setPageSize={setPageSize}
            jumpToPage={handleJumpToPage}
            numberOfPages={users && users?.length > 0 && users[0].num_of_pages}
          />
        </Box>
        <Text
          size="texts"
          color="gray_description"
          mt="346px"
          fontFamily="Arimo"
          fontWeight={500}
        >
          Origin360. Tous droits réservés © {new Date().getFullYear()}
        </Text>
      </Flex>
    </>
  );
}
