import { Box, Button, Container, Flex, FormControl, Heading, Image, Text, useToast } from "@chakra-ui/react";

import { useAuthentication } from "hooks/useAuthentication";
import { useContext } from "react";
import { Store } from "store";

// third party
import AppInput from "components/AppInput";
import { useFormik } from "formik";
import { showToast, toastTypes } from "utils";
import * as Yup from "yup";

export default function WelcomeSection() {
  const toast = useToast();
  const { dispatch } = useContext(Store);
  const {
    authProvider: { forgotPassword },
  } = useAuthentication();
  const initialValues = {
    email: "",
    submit: null,
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
  });

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    resetForm
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async ({ email }) => {
      try {
        const response: any = await forgotPassword({
          username: email,
        });
        if (response) {
          dispatch(
            showToast({
              toast: toast,
              message:
                "Le lien de réinitialisation du mot de passe a été envoyé à votre adresse e-mail",
              status: toastTypes.success,
            })
          );
          resetForm();
        } else {
          dispatch(
            showToast({
              toast: toast,
              message: "Email invalide",
              status: toastTypes.error,
            })
          );
        }
      } catch (ex) {
        dispatch(
          showToast({
            toast: toast,
            message: "Email invalide",
            status: toastTypes.error,
          })
        );
      }
    },
  });
  return (
    <>
      {/* welcome section */}
      <Flex flexDirection="column" alignItems="center" mt={10}>
        <Container
          gap={{ md: "20px", base: "88px", sm: "132px" }}
          display="flex"
          flexDirection="column"
          alignItems="center"
          px={{ md: "56px", base: "20px" }}
          p={{ md: 0, base: "20px" }}
        >
          <Flex
            w={{ md: "86%", base: "100%" }}
            justifyContent="center"
            flexDirection={{ md: "row", base: "column" }}
          >
            <Box
              w={{ md: "46%", base: "100%" }}
              display={{ md: "flex", base: "none" }}
            >
              <Flex
                borderTopLeftRadius="24px"
                borderBottomLeftRadius="24px"
                gap="20px"
                bg="red_origin"
                flexDirection="column"
                px={{ base: "20px", sm: "32px" }}
              >
                <Flex
                   mt="20px"
                   gap="16px"
                   flexDirection="column"
                   alignItems="start"
                >
                  <Text size="text3xl" color="white" textAlign={"center"}>
                  Bienvenue sur ORIGIN360
                  </Text>
                  <Text
                    size="textxl"
                    color="very_light_gray"
                    w="100%"
                    lineHeight="150%"
                    textAlign={"center"}
                  >
                    La plateforme de protection des marques par la vérification de l’authenticité d’articles et documents administratifs.
                  </Text>
                </Flex>
                <Box bg={"white"} h="200px"
                  w="200px" borderRadius={"25px"} mx={"auto"}>
                <Image
                  src="images/qr-code-login-1.png"
                  alt="Decorative Image"
                  h="200px"
                  w="200px"
                  fit="contain"
                  mx={"auto"}
                />
                </Box>
              </Flex>
            </Box>
            <Flex
              flex={1}
              flexDirection="column"
              alignItems="start"
              alignSelf={{ md: "auto", base: "stretch" }}
            >
              <form
                onSubmit={handleSubmit}
                style={{ flex: 1, alignSelf: "stretch" }}
              >
                <Flex
                  borderTopRightRadius="24px"
                  borderBottomRightRadius="24px"
                  gap="42px"
                  bg="white"
                  w={{ md: "88%", base: "100%" }}
                  flexDirection="column"
                  alignItems="center"
                  p={{ base: "20px", sm: "22px" }}
                  height={"100%"}
                >
                  <Flex
                    gap="2px"
                    w={{ md: "90%", base: "100%" }}
                    flexDirection="column"
                  >
                    <Image
                      src="images/logo.png"
                      alt="Login Image"
                      h="112px"
                      w="112px"
                      fit="contain"
                      mx={"auto"}
                    />
                    <Flex flexDirection="column" alignItems="start">
                      <Heading size="headingxl" as="h1">
                        Mot de passe oublié
                      </Heading>
                      <Text size="textxl" color="gray_description">
                        Entrez votre adresse email
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex
                    gap="8px"
                    w={{ md: "90%", base: "100%" }}
                    flexDirection="column"
                  >
                    <Flex gap="12px" flexDirection="column">
                      <FormControl isInvalid={!!errors.email && touched.email}>
                        <AppInput
                          required
                          placeholder={`ex: johon.doe@gmail.com`}
                          gap="8px"
                          borderWidth="1px"
                          alignSelf="stretch"
                          borderRadius="8px"
                          id="email"
                          name="email"
                          autoComplete="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email || ""}
                          showErrorMessage={Boolean(
                            touched.email && errors.email
                          )}
                          errorMessage={errors.email}
                        />
                      </FormControl>
                    </Flex>
                  </Flex>
                  <Flex
                    mb="46px"
                    gap="12px"
                    w={{ md: "34%", base: "100%" }}
                    flexDirection="column"
                  >
                    <Button
                      size="4xl"
                      colorScheme="red_origin"
                      fontWeight={700}
                      boxShadow="md"
                      alignSelf="stretch"
                      borderRadius="8px"
                      type="submit"
                      isLoading={isSubmitting}
                    >
                      Continuer
                    </Button>
                  </Flex>
                </Flex>
              </form>
            </Flex>
          </Flex>
          <Text
            size="texts"
            color="gray_description"
            fontFamily="Arimo"
            fontWeight={500}
          >
            Origin360. Tous droits réservés © {new Date().getFullYear()}
          </Text>
        </Container>
      </Flex>
    </>
  );
}
