import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Link,
  Text,
  useToast,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import AppDatePicker from "components/AppDatePicker";
import AppSelect from "components/AppSelect";
import { Pagination } from "components/Pagination";
import { useApi } from "hooks/useApi";
import { useCache } from "hooks/useCache";
import { useContext, useMemo, useRef, useState } from "react";
import { MdMap } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { Store } from "store";
import { closeLoader, openLoader, showToast, toastTypes } from "utils";
import { ReactTable } from "../../components/ReactTable";

type Table3RowType = {
  id: number;
  tracking_id: string;
  scan_time: string;
  product_name: string;
  category_name: string;
  company_name: string;
  ip_address: string;
  longitude: string;
  latitude: string;
  member: string;
  qr_code_id: number;
};

export default function LogManagementSection() {
  const loadingRef = useRef<any>(null);
  const { dispatch } = useContext(Store);
  const location = useLocation();
  const {startDate, endDate} = location.state as any || {};
  console.log(startDate, endDate)
  const [company, setCompany] = useState<any>("");
  const [category, setCategory] = useState<any>("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const toast = useToast();
  const { getLogs, generateLogReportCSV, getCompanies, getSubCategories } =
    useApi();
  const today = new Date()
  const [selectedDates, setSelectedDates] = useState<Date[]>([
     new Date(startDate?.split("-").reverse().join("-") ||  today.toDateString()),
    new Date(endDate?.split("-").reverse().join("-")||  today.toDateString()),
  ]);
  const { data: logs, mutate } = useCache("getReports", () =>
    getLogs({
      page: page,
      startDate: selectedDates[0]
          ?.toISOString()
          .split("T")[0]
          .split("-")
          .reverse()
          .join("-"),
        endDate: selectedDates[1]
          ?.toISOString()
          .split("T")[0]
          .split("-")
          .reverse()
          .join("-"),
      company: company,
      category: category,
    })
  );
  const {
    data: companies,
    mutate: mutateCompanies,
    isLoading: isCompanyLoading,
  } = useCache("companies", () => getCompanies(page));
  const {
    data: categories,
    mutate: mutateCategories,
    isLoading: isCategoryLoading,
  } = useCache("categories", () => getSubCategories(page));
  const handleJumpToPage = (pg: number) => {
    setPage(pg);
    mutate(
      getLogs({
        page: page,
        startDate: selectedDates[0]
          ?.toISOString()
          .split("T")[0]
          .split("-")
          .reverse()
          .join("-"),
        endDate: selectedDates[1]
          ?.toISOString()
          .split("T")[0]
          .split("-")
          .reverse()
          .join("-"),
        company: company,
        category: category,
      })
    );
  };
  const handleSetNextPage = () => {
    if (logs.length > 0 && logs[0].num_of_pages >= page + 1) {
      setPage((prev: any) => prev + 1);
      mutate(
        getLogs({
          page: page + 1,
          startDate: selectedDates[0]
            ?.toISOString()
            .split("T")[0]
            .split("-")
            .reverse()
            .join("-"),
          endDate: selectedDates[1]
            ?.toISOString()
            .split("T")[0]
            .split("-")
            .reverse()
            .join("-"),
          company: company,
          category: category,
        })
      );
    }
  };
  const handleSetPrevPage = () => {
    if (logs.length > 0 && page > 0) {
      setPage((prev: any) => prev - 1);
      mutate(
        getLogs({
          page: page + 1,
          startDate: selectedDates[0]
            ?.toISOString()
            .split("T")[0]
            .split("-")
            .reverse()
            .join("-"),
          endDate: selectedDates[1]
            ?.toISOString()
            .split("T")[0]
            .split("-")
            .reverse()
            .join("-"),
          company: company,
          category: category,
        })
      );
    }
  };
  const handleGenerateReportCSV = async () => {
    openLoader(loadingRef);
    const response = await generateLogReportCSV({
      startDate: selectedDates[0]
        .toISOString()
        .split("T")[0]
        .split("-")
        .reverse()
        .join("-"),
      endDate: selectedDates[1]
        .toISOString()
        .split("T")[0]
        .split("-")
        .reverse()
        .join("-"),
      company: company,
      category: category,
    });
    if (response) {
      dispatch(
        showToast({
          toast: toast,
          message: "Un email avec le lien de téléchargement vous sera envoyé.",
          status: toastTypes.success,
        })
      );
    } else {
      dispatch(
        showToast({
          toast: toast,
          message: "Impossible de générer le rapport.",
          status: toastTypes.error,
        })
      );
    }
    closeLoader(loadingRef);
  };
  const table3Columns = useMemo(() => {
    const table3ColumnHelper = createColumnHelper<Table3RowType>();
    return [
      table3ColumnHelper.accessor("tracking_id", {
        cell: (info) => (
          <Box flex={1}>
            <Flex gap="8px" alignItems="center" px="12px" py="16px">
              <Text>{info.getValue<string>()}</Text>
            </Flex>
          </Box>
        ),
        header: (info) => (
          <Flex flex={1} px={"25px"} py="14px">
            <Heading as="h6">Identifiant de suivi</Heading>
          </Flex>
        ),
        meta: { width: "200px" },
      }),
      table3ColumnHelper.accessor("company_name", {
        cell: (info) => (
          <Box flex={1}>
            <Flex gap="8px" alignItems="center" px="12px" py="16px">
              <Text>{info.getValue<string>()}</Text>
            </Flex>
          </Box>
        ),
        header: (info) => (
          <Flex flex={1} px={"25px"} py="14px">
            <Heading as="h6">Client</Heading>
          </Flex>
        ),
        meta: { width: "150px" },
      }),
      table3ColumnHelper.accessor("category_name", {
        cell: (info) => (
          <Box flex={1}>
            <Flex gap="8px" alignItems="center" px="12px" py="16px">
              <Text>{info.getValue<string>()}</Text>
            </Flex>
          </Box>
        ),
        header: (info) => (
          <Flex flex={1} px={"25px"} py="14px">
            <Heading as="h6">Catégorie</Heading>
          </Flex>
        ),
        meta: { width: "100px" },
      }),
      table3ColumnHelper.accessor("product_name", {
        cell: (info) => (
          <Box flex={1}>
            <Flex gap="8px" alignItems="center" px="12px" py="16px">
              <Text>{info.getValue<string>()}</Text>
            </Flex>
          </Box>
        ),
        header: (info) => (
          <Flex flex={1} px={"25px"} py="14px">
            <Heading as="h6">Produit</Heading>
          </Flex>
        ),
        meta: { width: "150px" },
      }),
      table3ColumnHelper.accessor("member", {
        cell: (info) => (
          <Box flex={1}>
            <Flex gap="8px" alignItems="center" px="12px" py="16px">
              <Text>{info.getValue<string>()}</Text>
            </Flex>
          </Box>
        ),
        header: (info) => (
          <Flex flex={1} px={"25px"} py="14px">
            <Heading as="h6">Utilisateur</Heading>
          </Flex>
        ),
        meta: { width: "150px" },
      }),
      table3ColumnHelper.accessor("scan_time", {
        cell: (info) => (
          <Box flex={1}>
            <Flex gap="8px" alignItems="center" px="12px" py="16px">
              <Text>{info.getValue<string>()}</Text>
            </Flex>
          </Box>
        ),
        header: (info) => (
          <Flex flex={1} px={"25px"} py="14px">
            <Heading as="h6">Date du scan</Heading>
          </Flex>
        ),
        meta: { width: "150px" },
      }),
      table3ColumnHelper.accessor("ip_address", {
        cell: (info) => (
          <Box flex={1}>
            <Flex gap="8px" alignItems="center" px="12px" py="16px">
              <Text>{info.getValue<string>()}</Text>
            </Flex>
          </Box>
        ),
        header: (info) => (
          <Flex flex={1} px={"25px"} py="14px">
            <Heading as="h6">adresse IP</Heading>
          </Flex>
        ),
        meta: { width: "100px" },
      }),
      table3ColumnHelper.accessor("id", {
        cell: (info) => (
          <HStack gap={"2px"}>
            <Link
              href={`https://www.google.com/maps/search/?api=1&query=${info.row.original.latitude},${info.row.original.longitude}`}
              target="_blank"
            >
              <IconButton
                variant="outline"
                colorScheme="gray_stroke_0"
                aria-label="view"
                size={"sm"}
                icon={<MdMap />}
                borderRadius={"4px"}
              />
            </Link>
          </HStack>
        ),
        header: (info) => (
          <Flex px="15px" py="14px">
            <Heading as="h6">Action</Heading>
          </Flex>
        ),
        meta: { width: "100px" },
      }),
    ];
  }, []);

  return (
    <>
      {/* dealer management section */}
      <Flex
        flexDirection="column"
        alignItems="start"
        px={{ base: "20px", sm: "24px" }}
        minHeight={"100vh"}
      >
        <Flex gap="4px" alignSelf="stretch" flexDirection="column">
          <Flex
            justifyContent="flex-start"
            alignItems="center"
            flexDirection={{ base: "column", sm: "row" }}
          >
            <Heading size="text2xl" as="h5" fontFamily="Arimo">
              Scans
            </Heading>
            <></>
          </Flex>
        </Flex>
        <Box
          mt="16px"
          bg="white"
          w={{ md: "100%", base: "100%" }}
          borderRadius="10px"
        >
          <Flex
            bg="white"
            justifyContent="center"
            alignItems="center"
            px="12px"
            py="16px"
            flexDirection={{ md: "row", base: "column" }}
          >
            <Flex
              flex={1}
              alignItems="center"
              alignSelf={{ md: "auto", base: "stretch" }}
              flexDirection={{ base: "column", sm: "row" }}
              gap={2}
            >
              <AppDatePicker
                selectedDates={selectedDates}
                onDateChange={(date) => {
                  setSelectedDates(date);
                  mutate(
                    getLogs({
                      startDate: date[0]
                        ?.toISOString()
                        .split("T")[0]
                        .split("-")
                        .reverse()
                        .join("-"),
                      endDate: date[1]
                        ?.toISOString()
                        .split("T")[0]
                        .split("-")
                        .reverse()
                        .join("-"),
                    })
                  );
                }}
              />
              <Box w={{ base: "100%", sm: "20%" }}>
                <AppSelect
                  onChange={(field: string, value: any) => {
                    setCompany(value);
                    mutate(
                      getLogs({
                        page: page,
                        startDate: selectedDates[0]
                          ?.toISOString()
                          .split("T")[0]
                          .split("-")
                          .reverse()
                          .join("-"),
                        endDate: selectedDates[1]
                          ?.toISOString()
                          .split("T")[0]
                          .split("-")
                          .reverse()
                          .join("-"),
                        company: value,
                        category: category,
                      })
                    );
                  }}
                  value={company}
                  placeholder="client"
                  options={companies}
                  isMulti={false}
                  isLoading={isCompanyLoading}
                  id="company"
                  name="company"
                  handleInputChange={(inputVal) => {
                    mutateCompanies(getCompanies(1, inputVal));
                  }}
                />
              </Box>
              <Box w={{ base: "100%", sm: "20%" }}>
                <AppSelect
                  onChange={(field: string, value: any) => {
                    setCategory(value);
                    mutate(
                      getLogs({
                        page: page,
                        startDate: selectedDates[0]
                          ?.toISOString()
                          .split("T")[0]
                          .split("-")
                          .reverse()
                          .join("-"),
                        endDate: selectedDates[1]
                          ?.toISOString()
                          .split("T")[0]
                          .split("-")
                          .reverse()
                          .join("-"),
                        company: company,
                        category: value,
                      })
                    );
                  }}
                  value={category}
                  placeholder="catégorie"
                  options={categories}
                  isMulti={false}
                  isLoading={isCategoryLoading}
                  id="category"
                  name="category"
                  handleInputChange={(inputVal) => {
                    mutateCategories(getSubCategories(1, inputVal));
                  }}
                />
              </Box>
            </Flex>
            <Button
              size="4xl"
              colorScheme="red_origin"
              fontWeight={700}
              boxShadow="md"
              alignSelf="stretch"
              borderRadius="8px"
              type="submit"
              onClick={handleGenerateReportCSV}
            >
              Générer un rapport
            </Button>
          </Flex>
          <ReactTable
            size="sm"
            headerCellProps={{ bg: "white" }}
            tableProps={{
              borderColor: "neutral.600",
              borderTopWidth: "1px",
              borderStyle: "solid",
            }}
            columns={table3Columns as any}
            data={logs}
          />
          <Pagination
            pageIndex={page}
            pageSize={pageSize}
            setNextPageIndex={handleSetNextPage}
            setPrevPageIndex={handleSetPrevPage}
            setPageSize={setPageSize}
            jumpToPage={handleJumpToPage}
            numberOfPages={logs && logs.length > 0 && logs[0]?.num_of_pages}
          />
        </Box>
      </Flex>
    </>
  );
}
